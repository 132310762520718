<template>
  <div class="vx-row" style="margin-top: 10px">
    <div class="vx-col md:w-1/1 w-full" style="margin-bottom: 160px">
      <vx-card title="Form Tax Invoice">
        <div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Company</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formInput.inputs" />
          </div>
        </div>
        <!-- <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Operating Unit</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs
              :components="this.formOu.inputs"
              @handlerSearch="handlerSearchMS"
              @update-forminput="this.updateforminput"
            />
          </div>
        </div> -->
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Supplier</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formSupplier.inputs" @handlerSearch="handlerSearchMS" />
          </div>
        </div>

        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Tax Invoice Period</span>
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <formInputs :components="this.formMonth.inputs" />
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <formInputs :components="this.formYear.inputs" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Search</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input v-model="table.search" class="w-full" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-4/5 w-full text-center">
            <vs-button class="mr-4" @click="handleView()" color="primary" icon-pack="feather">View
            </vs-button>
            <vs-button @click="handleExport()" :disabled="!this.itemRefs.some((dt) => dt.inputs[1].components.value)"
              color="danger" icon-pack="feather" type="border">Export
            </vs-button>
          </div>
        </div>
        <vs-row class="mb-6 ml-4 mr-4" vs-w="12">
          <vs-col class="mb-3" vs-type="flex" vs-justify="left" vs-align="center" vs-w="12">
            <span>Total Invoice : {{ this.itemRefs.length }}</span>
          </vs-col>
        </vs-row>
        <vs-divider class="mb-2"></vs-divider>
        <vs-tabs :color="colorx" v-model="tabs">
          <vs-tab @click="
            colorx = 'success';
          tabClick(0);
          " label="SN Export">
            <div class="con-tab-ejemplo">
              <datatable :itemRefs="this.itemRefs" :tablep="this.table" :modelItemRefInput="this.tmpitemRef"
                :hideSearch="true" @search-itemref="handleSearch" @update-itemref="updateitemref"
                @change-length="handleChangelength" @input-itemref="oninput" @click="handlerClick"
                @handleChangePage="handleChangePage" @handleSort="handleSort" />
            </div>
          </vs-tab>
          <vs-tab @click="
            colorx = 'warning';
          tabClick(1);
          " label="Data Export">
            <div class="con-tab-ejemplo">
              <!-- <vs-row class="mb-6 ml-4 mr-4">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12"> -->
              <!-- Export -->
              <export_table :hideSearch="true" type="tax-invoice-debit-note" />
              <!-- </vs-col>
              </vs-row> -->

            </div>
          </vs-tab>
        </vs-tabs>
        <!-- <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-5/5 w-full">
            <datatable
              :itemRefs="this.itemRefs"
              :tablep="this.table"
              :modelItemRefInput="this.tmpitemRef"
              @search-itemref ="handleSearch"
              @update-itemref="updateitemref"
              @change-length ="handleChangelength"
              @input-itemref="oninput"
              @click="handlerClick"
              @handleChangePage="handleChangePage"
            />
          </div>
        </div> -->
      </vx-card>
    </div>
  </div>
</template>

<script>
import datatable from "../datatable.vue";
import formInputs from "../form_inputs.vue";
import moment from "moment";
import export_table from "./export_table.vue"
export default {
  components: {
    datatable,
    formInputs,
    export_table
  },
  data() {
    return {
      tabs: 0,
      colorx: "success",
      selected: {},
      responseData: {},
      users: [],
      type: 0,
      tempSupplier: [],
      table: {
        start: 1,
        stripe: true,
        end: 0,
        page: 0,
        length: 10,
        model: {},
        search: "",
        order: "id",
        sst: true,
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],

      },
      data: {
        npwp: "",
      },
      formTaxEntity: {
        id: 1,
        inputs: {
          disabled: false,
          validate: "required",
          name: "taxt_entity",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formOu: {
        id: 2,
        inputs: {
          disabled: false,
          validate: "required",
          name: "operating_unit",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          internal_search: false,
          clear_on_select: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formSupplier: {
        id: 3,
        inputs: {
          disabled: false,
          validate: "required",
          name: "supplier",
          placeholder: "Type to search",
          type: "multiselect",
          value: [{ ID: 0, text: "All" }],
          option: [],
          multiple: true,
          allowempty: false,
          internal_search: false,
          clear_on_select: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formTaxInvoiceType: {
        id: 4,
        inputs: {
          disabled: false,
          validate: "required",
          name: "territory",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formInput: {
        id: 5,
        inputs: {
          disabled: false,
          validate: "required",
          name: "tax_entity",
          placeholder: "Tax Entity",
          type: "input",
          value: "",
        },
      },
      formYear: {
        id: 6,
        inputs: this.forminputyear(),
      },
      formMonth: {
        id: 7,
        inputs: this.forminputmonth(),
      },
      formInvoiceType: {
        id: 8,
        inputs: {
          disabled: false,
          validate: "required",
          name: "invoice_type",
          placeholder: "Type to search",
          type: "multiselect",
          value: {
            id: 1,
            text: "Debit Note"
          },
          option: [
            {
              id: 0,
              text: "AR Invoice"
            },
            {
              id: 1,
              text: "Debit Note"
            }
          ],
          multiple: false,
          allowempty: false,
          track_by: "id",
        },
      },
      itemRefs: [],
      tmpitemRef: {
        id: 2,
        action: false,
        inputs: this.getInputsHead(),
      },
      DataOperatingUnit: [],
      DataTerritory: [],
      data_head: {},
      total_sn_available: 0,
    };
  },
  mounted() {
    console.log("Router: ", this.$router);
    // alert(this.itemRefs.length>1)
    this.getData();
    this.getTaxInvoiceType();
  },
  methods: {
    resetTable() {
      this.table.total = 0
      this.table.totalPage = 0
      this.table.totalSearch = 0
      this.table.length = 10
      this.table.start = 0
      this.table.end = 0
      this.table.stripe = false
    },
    tabClick(i) {
      this.resetTable()
      if (i == 1) {
        // this.table.stripe = true
        // this.getDatGen()
      } else {
        // this.getDataSN()
        this.handleView()
      }
    },
    forminputyear() {
      return {
        disabled: false,
        validate: "required",
        name: "year",
        placeholder: "Type to search",
        value: "",
        type: "date",
        min_view: "year",
        format: 'yyyy',
        track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    forminputmonth() {
      return {
        disabled: false,
        validate: "required",
        name: "month",
        placeholder: "Select Month",
        value: "",
        type: "date",
        min_view: "month",
        format: 'MM',
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    handleSearch(search) {
      this.table.search = search
      this.handleView()
      // this.getData();
      // this.getTaxInvoiceType();
    },
    handleSort(order) {
      if (this.table.order == order) {
        if (this.table.sort == "desc") this.table.sort = "asc"
        else this.table.sort = "desc"
      }
      this.table.order = order;
      this.handleView()
    },
    handleChangePage(page) {
      if (this.tab == this.gen) {
        this.table.page = page;
        this.handleView()
      }
    },
    handleChangelength(item) {
      console.log(item)
      if (item == "All") {
        this.table.end = this.table.total
      } else {
        this.table.end = item
      }
      this.table.length = this.table.end
      this.handleView()
    },
    oninput(data, type) {
      console.log(data);
      if (type == "itemref") {
        var total_sn = 0;
        if (data.name == "start_number") {
          total_sn =
            this.itemRefs[data.id].inputs[5].components.value - data.value;
          if (total_sn < 0) {
            this.itemRefs[data.id].inputs[6].components.value = "" + 0;
            this.itemRefs[data.id].inputs[7].components.value = "" + 0;
            this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          } else {
            this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          }
        } else if (data.name == "end_number") {
          total_sn =
            data.value - this.itemRefs[data.id].inputs[4].components.value;
          if (total_sn < 0) {
            this.itemRefs[data.id].inputs[6].components.value = "" + 0;
            this.itemRefs[data.id].inputs[7].components.value = "" + 0;
            this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          } else {
            this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          }
        } else if (data.name == "checkbox") {
          if (data.head_val != undefined) {
            this.data_head = data;
            if (data.head_val == true) {
              this.itemRefs.forEach((dt, i) => {
                this.itemRefs[i].inputs[1].components.value = true;
              });
            } else if (data.head_val == false) {
              this.itemRefs.forEach((dt, i) => {
                this.itemRefs[i].inputs[1].components.value = false;
              });
            }
          } else {
            if (data.value == false) {
              if (this.data_head) {
                this.data_head.head_val = false;
              }
            }
          }
        }
      }
    },
    updateitemref(data, status) {
      if (status == "add") {
        let i_new = this.itemRefs.length;
        this.itemRefs.push(data);
        for (let i = 0; i < this.itemRefs[i_new].inputs.length; i++) {
          this.itemRefs[i_new].inputs[i].components.id = i_new;
          if (i != 0 && i != 1 && i != 2) {
            this.itemRefs[i_new].inputs[i].components.value = "";
          } else {
            this.itemRefs[i_new].inputs[i].components.value =
              this.itemRefs[i_new - 1].inputs[i].components.value;
          }
        }
      } else if (status == "remove") {
        this.itemRefs = this.itemRefs.filter((v, i) => {
          return i != data;
        });
      }
    },
    getInputs(data = null, i = 0, no = 0) {
      var inputs = [];
      var periode = "";
      var dn_code = "";
      var customer_code = "";
      var customer_name = "";
      var tax_number = "";
      var tax_type = "";
      var countDownload = 0
      var file_link = ""
      var file_name = ""
      var export_date = ""
      let taxNumberGenerateDate = ""
      if (data) {
        periode = moment(data.PostingDate).format('YYYY-MM-DD');
        dn_code = data.Code;
        customer_code = data.SupplierCode;
        customer_name = data.SupplierName;
        tax_number = data.TaxNumber;
        tax_type = data.TaxType;
        file_link = data.FilePath
        file_name = data.FileName
        export_date = data.ExportDate
        countDownload = "" + data.ExportCount
        taxNumberGenerateDate = data.TaxNumberGenerateDate
      }

      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 1,
          disabled: false,
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "title", //checkbox/title
          type: "checkbox",
          value: false,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "tax_invoice_date",
          placeholder: "Tax Invoice Date",
          title: "Tax Invoice Date",
          type: "text",
          value: periode,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "tax_invoice_date",
          placeholder: "DN Code",
          title: "DN Code",
          type: "text",
          value: dn_code,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "tax_invoice_number",
          placeholder: "Tax Invoice Number",
          title: "Tax Invoice Number",
          type: "text",
          value: tax_number,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "text",
          value: tax_type,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "customer_code",
          placeholder: "Customer/Supplier Code",
          title: "Customer/Supplier Code",
          type: "text",
          value: customer_code,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "customer_name",
          placeholder: "Customer/Supplier Name",
          title: "Customer/Supplier Name",
          type: "text",
          value: customer_name,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 8,
          disabled: true,
          validate: "required",
          name: "tax_generate_date",
          placeholder: "Generate Date",
          title: "Generate Date",
          type: "text",
          value: taxNumberGenerateDate,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 9,
          disabled: true,
          validate: "required",
          name: "export_count",
          placeholder: "Export Count",
          title: "Export Count",
          type: "text",
          value: countDownload,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 10,
          disabled: true,
          validate: "required",
          name: "export_date",
          placeholder: "Last Export Date",
          title: "Last Export Date",
          type: "text",
          value: export_date,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 11,
          disabled: true,
          validate: "required",
          name: "file",
          placeholder: "File",
          title: "File",
          target: "_blank",
          href: file_link,
          handler_event: true,
          type: "text-link",
          value: file_name,
        },
      });

      return inputs;
    },
    getTaxInvoiceType() {
      var data_select = [];
      data_select.push({
        ID: -1,
        text: "All",
      });
      for (let i = 0; i < 10; i++) {
        data_select.push({
          ID: i,
          text: i + 1,
        });
      }
      this.formTaxInvoiceType.inputs.option = data_select;
      this.formTaxInvoiceType.inputs.value = data_select[0];
    },
    getInputsHead() {
      var inputs = [];

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "input",
          value: "1",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "checkbox", //checkbox/title
          head_val: false, //for head=checkbox
          type: "checkbox",
          value: false,
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "posting_date",
          placeholder: "Tax Invoice Date",
          title: "Tax Invoice Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "code",
          placeholder: "DN Code",
          title: "DN Code",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "tax_number",
          placeholder: "Tax Invoice Number",
          title: "Tax Invoice Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "suplier_code",
          placeholder: "Customer/Supplier Code",
          title: "Customer/Supplier Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "suplier_name",
          placeholder: "Customer/Supplier Name",
          title: "Customer/Supplier Name",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 8,
          disabled: true,
          validate: "required",
          name: "tax_number_generate_date",
          placeholder: "Generate Date",
          title: "Generate Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 9,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Export Count",
          title: "Export Count",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 10,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Last Export Date",
          title: "Last Export Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 11,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "File",
          title: "File",
          type: "input",
          value: "",
        },
      });

      return inputs;
    },
    getIDItemRef() {
      return 0;
    },
    // data-taxallocation
    // getDataTaxAllocation() {
    //   const params = {
    //     operating_unit_id: this.DataOperatingUnit[this.formOu.inputs.value.ID]
    //       ? this.DataOperatingUnit[this.formOu.inputs.value.ID].ID
    //       : 0,
    //   };
    //   this.$http
    //     .get(`api/v1/master/tax-invoice/data-taxallocation`, { params })
    //     .then((resp) => {
    //       console.log(resp);
    //       if (resp.code == 500) {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       } else if (resp.code == 200) {
    //         this.total_sn_available = resp.data.count;
    //       } else {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       }
    //       this.$vs.loading.close();
    //     })
    //     .catch((err) => {
    //       this.$vs.loading.close();
    //       this.$vs.notify({
    //         color: "danger",
    //         title: "Error",
    //         text: err,
    //         position: "top-right",
    //         iconPack: "feather",
    //         icon: "icon-x-circle",
    //       });
    //       console.log("error boss:", err);
    //     });
    // },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handlerClick(comp) {
      var downloadFile = 10
      if (comp.id_input == downloadFile) {
        const path = comp.href
        const tax_number_id = this.itemRefs[comp.id].tax_number_id
        this.handlerDownload(path, tax_number_id, comp.id)
      }
    },
    handlerDownload(path = "", id, i = 0) {
      if (path != "") {
        const params = {
          path: path,
          id: id
        };
        this.$vs.loading();
        this.$http
          .get(`api/v1/master/tax-invoice/export/download`, { params })
          .then((resp) => {
            this.$vs.loading.close();
            console.log("resp", resp);
            if (resp.code == 200) {
              // var fileURL = window.URL.createObjectURL(new Blob([resp]));
              var fileLink = document.createElement("a");
              fileLink.target = "_blank"
              fileLink.href = resp.data.file_url;

              // if (file == "excel") {
              // fileLink.setAttribute("download", "Tax-Invoice.csv");
              fileLink.setAttribute("download", "Tax-Invoice.csv");
              // } else {
              // fileLink.setAttribute("download", fileTitle + ".pdf");
              // }
              document.body.appendChild(fileLink);
              fileLink.click();
              // const countExport = 7
              // this.itemRefs[i].inputs[countExport].components.value =""+resp.data.is_downloaded
              // this.handleView();
              // this.getDataTaxAllocation();
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "file doesn't exist",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    handleView() {
      if (this.formMonth.inputs.value && this.formYear.inputs.value) {
        this.$vs.loading();
        var month = "00"
        var year = "2022"
        if (this.formMonth.inputs.value) {
          month = this.formMonth.inputs.value.getMonth()
        }
        if (this.formYear.inputs.value) {
          year = this.formYear.inputs.value.getFullYear()
        }
        const params = {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          // territory_id:
          //   this.DataTerritory[this.formSupplier.inputs.value.ID].TerrID,
          // supplier_id: this.formSupplier.inputs.value.ID,
          supplier_ids: this.formSupplier.inputs.value.map(s => s.ID),
          invoice_type: this.formInvoiceType.inputs.value.id,//debit note=>1
          tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
          // tax_type: this.formTaxInvoiceType.inputs.value.text,

        };
        this.$http
          .get(`api/v1/master/tax-invoice/view-invoice-export`, { params })
          .then((resp) => {
            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              var dataInvoice = [];
              this.table.total = resp.data.total_record
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.setStartEnd()
              var no = this.table.start
              resp.data.dataInvoice.forEach((dt, i) => {
                if (i < 1000) {
                  dataInvoice.push({
                    id: i,
                    id_invoice: dt.ID,
                    tax_number_id: dt.TaxNumberID,
                    action: false,
                    inputs: this.getInputs(dt, i, no),
                  });
                }
                no++
              });
              this.itemRefs = dataInvoice;
              if (this.data_head) {
                this.data_head.head_val = false;
              }
              // this.getDataTaxAllocation();
            }
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      }
    },
    handleExport() {
      this.$vs.loading();
      var month = "00"
      var year = "2022"
      if (this.formMonth.inputs.value) {
        month = this.formMonth.inputs.value.getMonth()
      }
      if (this.formYear.inputs.value) {
        year = this.formYear.inputs.value.getFullYear()
      }
      // var data_id = "";
      var data_id = [];
      var item = this.itemRefs.filter((dt) => {
        return dt.inputs[1].components.value;
      });

      item.forEach((dt, i) => {
        if (dt.inputs[1].components.value) {
          data_id.push(dt.id_invoice)
          // data_id += dt.id_invoice;
          // if (i < item.length - 1) {
          //   data_id += ",";
          // }
        }
      });
      // console.log(data_id);
      var tax_invoice_type = "";
      if (this.formTaxInvoiceType) {
        tax_invoice_type = this.formTaxInvoiceType.inputs.value.text;
      }
      const params = {
        // params: {
        operating_unit_id: this.DataOperatingUnit[this.formOu.inputs.value.ID]
          ? this.DataOperatingUnit[this.formOu.inputs.value.ID].ID
          : 0,
        // territory_id: this.DataTerritory[this.formSupplier.inputs.value.ID]
        //   ? this.DataTerritory[this.formSupplier.inputs.value.ID].TerrID
        //   : 0,
        territory_id: this.formSupplier.inputs.value.ID,
        supplier_ids: this.formSupplier.inputs.value.map(s => s.ID),
        tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
        tax_invoice_type: tax_invoice_type,
        type_exp: "csv",
        invoice_type: this.formInvoiceType.inputs.value.id,//debit note=>1
        data_id: data_id,
        is_all: false,
        // },
      };
      this.$http
        .post(`api/v1/master/tax-invoice/export`, params)
        .then((resp) => {
          this.$vs.loading.close();
          console.log("resp", resp);
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleView();
            // this.getDataTaxAllocation();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    handleEdit() { },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getData() {
      this.$vs.loading();
      const params = {
        type: 1,
      }
      // console.log(this.formInput)
      this.$http
        .get(`api/v1/master/tax-invoice`, { params })
        .then((resp) => {
          // console.log(this.option.event, 'option')
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.formInput.inputs.value = resp.data.company;
            this.formInput.inputs.disabled = true;
            console.log(this.formInput);
            var dataSelect = resp.data.supplier.map(s => ({ ID: s.ID, text: `${s.Code} - ${s.Name}` }));
            this.tempSupplier = [{ ID: 0, text: "All" }, ...dataSelect]
            this.formSupplier.inputs.option = [{ ID: 0, text: "All" }, ...dataSelect]
            this.formSupplier.inputs.value = [{ ID: 0, text: "All" }];
            // var dtou = resp.data.dtOu;
            // this.DataOperatingUnit = resp.data.dtOu;
            // if (dtou) {
            //   let dataSelect = [];
            //   dtou.forEach((dt, i) => {
            //     dataSelect.push({
            //       ID: i,
            //       text: dt.Code + " - " + dt.Name,
            //     });
            //   });
            //   this.formOu.inputs.value = dataSelect[0];
            //   this.tempSupplier = dataSelect
            //   this.formOu.inputs.option = dataSelect;
            //   console.log(this.formOu.inputs.value)
            // }
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    updateforminput(selected, component) {
      if (component.name == "operating_unit") {
        this.getDataTerritory(selected);
      } else if (component.name == "allocation_type") {
        this.automateInputTaxNumber(selected);
      }
    },
    handlerSearchMS(comp, search) {
      if (comp.name == "supplier") {
        this.formSupplier.inputs.option = this.tempSupplier.filter(v => {
          var tempText = v.text.toLowerCase()
          var tempSearch = search.toLowerCase()
          return tempText.search(tempSearch) >= 0
        })
      }
    },
    // getDataTerritory(selected = null) {
    //   this.$vs.loading();
    //   if (selected) {
    //     const params = {
    //       id_ou: this.DataOperatingUnit[selected.ID].ID,
    //     };
    //     // console.log(this.formInput)
    //     this.$http
    //       .get(`api/v1/master/tax-invoice/territory`, { params })
    //       .then((resp) => {
    //         console.log("getDataTerritory", resp.data.dataTerr);
    //         // console.log(this.option.event, 'option')
    //         this.$vs.loading.close();
    //         if (resp.code == 500) {
    //           this.$vs.notify({
    //             color: "danger",
    //             title: "Error",
    //             text: resp.message,
    //             position: "top-right",
    //             iconPack: "feather",
    //             icon: "icon-x-circle",
    //           });
    //         } else if (resp.code == 200) {
    //           var dataSelect = [];
    //           if (resp.data.dataTerr) {
    //             this.DataTerritory = resp.data.dataTerr;
    //             this.DataTerritory.forEach((dt, i) => {
    //               dataSelect.push({
    //                 ID: i,
    //                 text: dt.Code + " - " + dt.Name,
    //               });
    //             });
    //           }
    //           this.formSupplier.inputs.value = dataSelect[0];
    //           this.formSupplier.inputs.option = dataSelect;
    //         }
    //       })
    //       .catch((err) => {
    //         this.$vs.loading.close();
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: err,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //         console.log("error boss:", err);
    //       });
    //   }
    // },
  },
};
</script>